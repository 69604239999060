import { graphql } from "gatsby"

import ActionPage from "../templates/actionPages"

export default ActionPage

export const pageQuery = graphql`
  query {
    landingPageData: markdownRemark(frontmatter: { slug: { eq: "page-404" } }) {
      frontmatter {
        seoTitle
        seoDescription
        slug
        displayHeaderBanner
        headerBannerText
        header
        blocks {
          background_image
          USPType
          title
          searchTitle
          subtitle
          type
          firstTextBefore
          firstTextAfter
          firstTextAnimated
          firstTextAnimation
          secondTextBefore
          secondTextAfter
          secondTextAnimated
          secondTextAnimation
          thirdTextBefore
          thirdTextAfter
          thirdTextAnimated
          thirdTextAnimation
          workContent {
            image {
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            text
            link
            url
          }
          content
          dividerSize
          variant
          ctaTitle
          ctaLink
          isBlockDisplayable
          listingTitle
          listingSubtitle
          text
          emailPlaceholder
          buttonText
          isImageFirst
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          uspImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          uspMobileImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          numberImage {
            childImageSharp {
              fixed(width: 460) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          firstText
          secondText
          backgroundImageTopBloc {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 450) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          buttonLink
          displayBackground
          hasCustomColor
          customColor
          backgroundColor
          titleColor
          textColor
          isAdressInput
          color
          linkColor
          linkText
          textBackgroundColor
          isBackgroundWhite
          repairmenCarouselSubTitle
          trustTag
          blocColor
          marginAfterBloc
          secondBackgroundColor
          pressArticles {
            pressAltPicture
            pressLink
            pressPicture
            siteName
          }
          products {
            productAltPicture
            productPicture
            productName
            productLink
          }
          highlightText
          highlightLink {
            title
            url
          }
          isQuoteSlider
          DIYButton
          nosHerosSlides {
            name
            description
            backgroundImage {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          primaryBackgroundColor
          secondaryBackgroundColor
          url
          DIYSubtitle
          DIYTitle
          link
          ReconditionButton
          ReconditionSubtitle
          number
          ReconditionTitle
          numberBackgroundColor
          displayNumber
          numberColor
          RepairButton
          RepairSubtitle
          RepairTitle
          RepairTooltipContent
          DIYContent {
            row
          }
          RepairContent {
            row
          }
          ReconditionContent {
            row
          }
          links {
            title
            url
          }
          monoOfferImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 450) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          monoOfferImageAlt
          monoOfferRepairButton
          monoOfferRepairSubtitle
          monoOfferRepairTitle
          monoOfferRepairTooltipContent
          monoOfferRepairContent {
            row
          }
          newHeaderImage
          newHeaderImageAlt
          newHeaderTitle
          newHeaderSubTitle
          newHeaderTextContent
          newHeaderTextBottom
          optInImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 512) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          optInImageAlt
          optInTitle
          mobileImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 512) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          optInButton
          optInText
          optInTextRgpd
          slides {
            title
            description
            picto {
              publicURL
            }
            link
            linkText
          }
          stepperSlides {
            title
            text
            number
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 512) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          machinaSlides {
            title
            text
            backgroundColor
            textColor
            backgroundImage {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            href
          }
          videoSlides {
            text
            backgroundImage {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            href
            icon
          }
        }
      }
    }
    headerData: markdownRemark(
      frontmatter: { headerName: { eq: "Yellow action header" } }
    ) {
      frontmatter {
        headerName
        enabledPhoneRenderer
        backgroundColor
        links {
          link {
            title
            url
            openLinkInNewTab
            picto {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 450) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            menuItem {
              icon {
                extension
                publicURL
              }
              title
              link
              subTitle
            }
            subItemBlock {
              backgroundColor
              title
              subTitle
              url
              link
            }
          }
        }
      }
    }
    footerData: markdownRemark(
      frontmatter: { footerName: { eq: "Default footer" } }
    ) {
      frontmatter {
        navigationBlocks {
          navigationBlock {
            footerBlockTitle
            footerBlockLinks {
              footerBlockLink {
                title
                url
                openLinkInNewTab
              }
            }
          }
        }
        cgv {
          title
          url
          openLinkInNewTab
        }
      }
    }
    productData: allProduct {
      nodes {
        name
        _id
        image
        slug
      }
    }
  }
`
